.banner {
    box-shadow: -5px 5px 0px 0px #C9E4DC;
    border: 2px solid black;
    border-radius:20px;
    margin-top:40px;
    padding:25px;
    box-sizing: border-box;
    h3 {
        font-size: 1.5em;
        margin-bottom: 0px;
        margin-top:0px;
    }
    h4 {
        margin-top:5px;
        font-weight: 400;
    }
    .button {
        margin-top:5px;
    }
}