.card {
    border-radius: 12px;
    margin: 1rem 0 1rem 0; 
    background-color: white;
    border: 2px solid black;
    box-shadow: --0px 10px 20px  #9dd9cb; 
    position: relative;
    padding: 1rem 2rem 1rem 2rem; 

    button {
        position: absolute;
        bottom: 1.5rem;
        right: 2rem;
        width: 125px;
        border-radius: 1.5rem; 
        margin-right: 1.2rem;
        z-index: 99;
    } 

    @media only screen and (max-width: 400px) {
      button {
        position: initial;
        display: block;
        margin:0 auto;
        margin-top:15px;
      }
    }

    p { 
      margin : 0px;   
    }

    h3 {
        padding-bottom: 0.5rem;
    }

    h4 {
        padding-bottom: 2rem;
    }
  }
  
  .title {
    text-align: left;
  }
  
  .card p {
    margin-top: 0;
    margin-bottom: 25px;
  } 

  .card-bg svg {
    width:500px;
    max-width: 100%;
    
  }

  .card-bg {
    padding-top:100px;
    padding-bottom: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 90%;
  }

  .card-img {
    padding-top: 35px;
    width: 150px;
    max-width: 100%;
  }

  .min-h-434 {
    min-height: 434px;
  }

  .max-w-700 {
    max-width: 700px;
    margin: 0 auto;
  }

  .dash-card {
    z-index: 0;
  }

  .item-name {
    max-width:125px;
  }

  .item-title {
    max-width: 125px;
  }

  .card-content-container {
    background-color: rgba(255, 255, 255, 0.7); /* Set the opacity to 0.7 */
    width: fit-content;
    border-radius: 8px;
    z-index: 99;
    padding: 5px;
    border-radius: 15px;
  }