
.chat-container {
  box-sizing: border-box;
  @media only screen and (max-width: 700px) {
    .message {
      width: 100%;
      float: none;
      box-sizing: border-box;
    }
  }
    /* Add styling for the chat container */
    .input-container {
      position: static;
    }
  }
  .button {
    margin-top:25px;
  }

  .send-form {
    padding:15px;
    margin-top:25px;
  }
  
  .message {
    padding: 10px;
    border-radius: 10px;
    margin-top:15px;
    width: 50%;
  }
  
  .item-owner {
    background-color: #e6f7ff;
    float: left;  
  }
  
  .item-finder {
    background-color: #fff;  
    float: right;
  }
  
  .time-right {
    color: #aaa;
  }
  