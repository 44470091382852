@import '../variables'; 

input, textarea {
    font-size: 16px;;
}

.input-container {
    position: relative;
    margin-bottom: 20px; 
    padding-top: 10px;  
}

input:focus, textarea:focus {
    outline: none;
  }
  
.input-container label {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
  
.input-container input {
    background-color: $primary-bg-color;
    height: 2rem;
    width: -webkit-fill-available;
    border: none;
    border-bottom: 2px solid black;
    padding-left: 5px;
}

::placeholder {
    color : black;
    font-family: "roc-grotesk", sans-serif;
    font-weight: 500;
    font-style: normal;
 }

.input-container textarea {
    background-color: $primary-bg-color;
    height: 4rem;
    width: -webkit-fill-available;
    border: none;
    border-bottom: 2px solid black;
    font-family: "roc-grotesk", sans-serif;
} 

.input-container textarea::placeholder {
    color : black;
    font-family: "roc-grotesk", sans-serif;
    font-weight: 500;
    font-style: normal;
}

label {
    font-size: 12px;
}

.input-container input[type="password"] {
    font-weight: 700;
}
