
.grid {
    display: grid;
}

.flex {
    display: flex;
}

.text-center {
    text-align: center;
}

.cols-1 {
    grid-template-columns: repeat(1, 1fr);
}

.cols-2 {
    grid-template-columns: repeat(2, 1fr);
}
.cols-3 {
    grid-template-columns: repeat(3, 1fr);
}

.cols-4 {
    grid-template-columns: repeat(4, 1fr);
}
.cols-5 {
    grid-template-columns: repeat(5, 1fr);
}

.cols-6 {
    grid-template-columns: repeat(6, 1fr);
}

.card-how {
    margin-bottom:30px;
}