@import '_variables';
@import 'layout/grid.scss';
@import './components/banner.scss';
@import './components/campaign.scss';

.link {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    margin-bottom:10px;
    display: block;
}

.pointer {
    cursor: pointer;
}

.not-found-button {
    margin-top:25px;
    display: inline-block;
}

input, p {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
    font-style: normal;
}

label {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 500;
    font-style: normal;
}
h1, h2, h3, h4 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 600;
}

a {
    text-decoration: none;
}

a:hover {
    cursor: pointer;
}

.container { 
    max-width: 1216px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;

}

.w-full {
    width:100%;
}


.fullwidth {
    display: flex;
    width: 100vw;
    margin-left: -50vw!important;
    margin-right: -50vw!important;
    left: 50%;
    right: 50%;
    position: relative;
}

.found-image-header {
    right:0;
    top:0;
}

@media only screen and (max-width: 320px) {
    .found-image-header {
        display: none;
    }
}

.hidden {
    display: none !important;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}
.roc-reg {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.roc-med {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.roc-bold {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.pb-20 {
    padding-bottom:20px;
}

.mx-auto {
    margin:0 auto;
}

.max-w-100 {
    max-width: 100%;
    display: block;

    iframe {
        max-width: 100%;
    }

    img {
        max-width: 100%;
    }
}

.loading-container {
    height: 100vh;
    width: 100vw;
    background-color: rgb(233, 245, 241);
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.captcha-container {
    margin-bottom: 50px;
}

/* Add this CSS to your stylesheets */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #000;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
  

