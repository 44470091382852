// colours
$primary-color: #E9F5F1;
$secondary-color: #00ff00;

$primary-bg-color: #F7F7F7; 
$secondary-bg-color: #E9F5F1; 

// font sizes
$font-size-standard: 18px;
$font-size-medium: 16px;
$font-size-small: 14px; 