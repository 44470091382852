.dob input {
    padding-left:45px;
}

.dob label {
    top:26px;
    font-size: 14px;
}

.dash-card {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: calc(100% - 30px) 30px;

    p, a, h3, h4  {
        z-index: 99;
    }
}

.dash-card-img {
    position: absolute;
    right:15px;
    top:15px;
    height: calc(100% - 30px);
    z-index: 0;
}