// Variables
$burger-size: 24px;
$burger-spacing: 8px;
$burger-color: #333;
$line-height: 2px;
$transition-duration: 0.3s;

.burger-menu {
    position: relative;
    float: left;
    z-index: 9999;
  }
  
  .burger-toggle {
    display: none;
  }
  
  .burger-icon {
    cursor: pointer;
    display: block;
    position: relative;
    width: $burger-size;
    height: $burger-size;
    z-index: 2;
  
    .line {
      background-color: $burger-color;
      position: absolute;
      left: 0;
      width: 100%;
      height: $line-height;
      transition: transform $transition-duration;
  
      &:nth-child(1) {
        top: 0;
        transform-origin: top left;
      }
  
      &:nth-child(2) {
        top: ($burger-size / 2) - ($line-height / 2);
        transform-origin: left;
      }
  
      &:nth-child(3) {
        bottom: 0;
        transform-origin: bottom left;
        width: 50%; // Add this line to make the last line half the width
      }
    }
  
    &.active {
      .line:nth-child(1) {
        transform: rotate(50deg) translate(-1px, 0);
        width: 128%;
      }
  
      .line:nth-child(2) {
        display: none;
      }
  
      .line:nth-child(3) {
        transform: rotate(-50deg) translate(-1px, 0);
        width: 131%; // Add this line to make the last line full width when clicked
      }
    }
  }
  .menu-container {
    width:205px;
    margin-left:15vw;
    text-align: left;
    padding-top:175px;
  }

  .menu-bg {
    position: absolute;
    width: 100vw;
    right:-65vw;
    z-index: 1;
    top:100px;
  }
  
  .menu {
    display: none;
    z-index: 1;
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: #E9F5F1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    opacity: 0;
    visibility: hidden;
    transition: opacity $transition-duration, visibility 0s $transition-duration;
  
    &.open {
      opacity: 1;
      visibility: visible;
    }
  
    li {
      margin-bottom: $burger-spacing;
  
      &:last-child {
        margin-bottom: 0;
      }
  
      a {
        color: $burger-color;
        text-decoration: none;
        font-size:24px;

        position: relative;
        z-index: 2;
      }
    }
  }
  
  .burger-menu .active ~ .menu {
    display: flex;
    opacity: 1;
    visibility: visible;
    transition-delay: $transition-duration;
  }
  
  .menu-logo {
    display: inline-block;
    z-index: 10;
    position: relative;
    h4 {
      margin-top: -10px;
    }
  }

  .menu-profile {
    float: right;
    z-index: 10;
    position: relative;
  }

  .nav-container {
    text-align: center;
  }

  .menu-container {
    li {
      a {
        font-family: "roc-grotesk", sans-serif;
    font-weight: 600;
    font-style: normal;
      }
    }
  }

  @media only screen and (min-width: 600px) {
    .menu-container {
      width: 400px;
      li {
        a {
          font-size:36px;
        }
      }
    }
  }