@import '../utilities.scss';

.home-header {
    display: flex;
    padding-top: 80px;
    padding-bottom: 90px;
}

.card-icon {
    position: absolute;
    right:-10px;
    top:-10px;
    background-color: #fff;
    height:15px;
    width:15px;
    padding:10px;
    border-radius: 100%;
    border: 1px solid #1D1D1B;
    box-shadow: -3px 3px 0px 0px #C9E4DC;
}

.line-break {
    flex-basis: 100%;
  }

.hero-text-container   {
    position: relative;
    width: fit-content;
    h1 {
        color:#fff;
        margin-bottom: 50px;
        padding-right: 100px;
        max-width: 600px;
        margin-top: 0px;;
    }
    img {
        margin-left: 10px;
        margin-top:40px;
        position: absolute;
        height:90px;
        right:20px;
        top:-45px;
    }
}

@media only screen and (max-width: 700px) {
    .hero-text-container {
        padding-left: 20px;
        padding-right: 20px;
    }
    .hero-text-container h1  {
        font-size: 20px;
    }
  }

.hero-button {
    margin-top: 25px;
}

.home-welcome {
    margin-top:60px;
    font-size: 20px;
}

.home-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin-top:40px;
    p {
        font-size: 18px;
    }

     a {
        margin-top:10px;
        display: inline-block;
    }
}

@media only screen and (max-width: 700px) {
    .home-grid {
        grid-template-columns: repeat(1, 1fr);
    }
  }

.card {
    border: 1px solid #1D1D1B;
    border-radius: 6px;
    padding:25px;
    display: flex;
  flex-direction: column;
    box-shadow: -5px 5px 0px 0px #C9E4DC;

    h3 {
        margin: 0;
    }
    a {
        bottom:20px;
        margin-top:10px;
        margin-top: auto;
    }
}