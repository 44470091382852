.campaign-page {
    padding-top:50px;
    h1 {
        text-transform: capitalize;
    }

    p {
        max-width: 700px;
        margin:0 auto;
    }
}