h1, h2, h3, h4 {
    font-family: "roc-grotesk", sans-serif;
}

ul, ol {
    margin-left: 0px;
    padding-left: 15px;
}

p, li {
    font-family: "roc-grotesk", sans-serif;
}

h2 {
    font-size: 35px;
}

h3 {
    font-size: 20px;
}

.mt-0 {
    margin-top:0 !important;
}

.white-form {
    background-color: #fff;
    border: 1px solid #1D1D1B;
    margin-bottom: 35px;
    border-radius: 6px;
    padding-top: 3px;

    .input-container {
        padding:0px;
        margin:0px;
    }

    input {
        background-color: #fff;
        padding-left: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid #1D1D1B;
        padding-top: 10px;
    }

    textarea {
        background-color: #fff;
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 0px;
    }
}

.no-border {
    border:0px;
    background-color: transparent;
    input {
        border:1px solid;
    }
}

.how-to-container {
    margin-top:20px;
    display: inline-block;
}

.how-it-works-img {
    img {
        max-width: 70%;
        float: right;
        border-radius: 20px;
    }
}

.card-how {
    display: grid;
    columns: 2;
}

.how-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.how-cols {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
@media only screen and (max-width: 700px) {
    .how-cols {
        grid-template-columns: repeat(1, 1fr);
    }
    .how-it-works-img {
        text-align: center;
        img {
            max-width: 100%;
            float:none;
            border-radius: 20px;
            margin: 0 auto;
        }
    }
  }