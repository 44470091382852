.accordion-container {
        background-color: #fff;
        border:1px solid black;
        padding:20px;
        border-radius: 15px;
    .accordion {
        position: relative;
        &:not(:last-child) {
            margin-bottom: 15px;
            .accordion-title {
                border-bottom: 1px solid black;
                padding-bottom: 15px;
            }
        }
        .accordion-title {
            padding-bottom: 0px;
            padding-right: 50px;
            cursor: pointer;
            p {
                font-weight: 550;
            }
        }

        .accordion-arrow {
            height:15px;
            position: absolute;
            top:0px;
            right:25px;
            cursor: pointer;
            transition: transform 0.3s ease; /* Add a transition for smooth rotation */
        }

        .accordion-text {
            padding-top:15px;
            display: none;
        }

        p {
            margin: 0px;
        }
    }
}

.accordion.open .accordion-text {
    display: block;
}

.rotate {
    transform: rotate(120deg); /* Rotate the arrow down when the accordion is open */
    transform: rotate(90deg);
}