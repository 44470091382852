@import '../variables'; 

.button { 
    border: 2px solid #1D1D1B; 
    border-radius: 6px;
    background: white; 
    font-size: 16px;
    color: #1D1D1B; 
    padding: 15px 30px;
    font-family: "roc-grotesk", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.dash-button {
    border: 2px solid #1D1D1B; 
    border-radius: 6px;
    background: white; 
    font-size: 16px;
    color: #1D1D1B; 
    padding:10px;
    font-family: "roc-grotesk", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.button-white {
    border: 2px solid #707070; 
    border-radius: 6px;
    background: white; 
    font-size: 16px;
    color: #1D1D1B; 
    padding: 15px 30px;
    width: fit-content;
    font-family: "roc-grotesk", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.big-button-white {
    border: 2px solid #707070; 
    border-radius: 6px;
    background: white; 
    font-size: 16px;
    color: #1D1D1B; 
    padding: 15px 30px;
    width: 100%;
    font-family: "roc-grotesk", sans-serif;
    font-weight: 600;
    font-style: normal;
    display: inline-block;
    text-align: center;
}

.button:hover, .button-white:hover, .big-button-white:hover {
    background-color: $secondary-bg-color;
    cursor: pointer;
}

.card-button {
    padding:10px 5px 8px 10px;   
}

.edit-tag-btn {
    margin-right: 25px;
}

.login-buttons {
    display: flex;
    gap:10px;
    margin-top:25px;
}