@import '../utilities.scss';
@import 'burger-menu.scss';

.main-header {
    padding-top: 21px;
    padding-bottom: 21px;
}

main {
    min-height: 90vh;
}

body {
    overflow-x: hidden;
}

footer {
    margin-top:80px;
}